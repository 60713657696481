import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";

function Header({ toggleSidebar }) {
  

  return (
    <header className="fixed z-30 w-full h-24 bg-white">
    <nav className="max-w-1170 mx-auto flex justify-between align-middle h-full px-4 xl:px-0 ">
      
      <AnchorLink to='/#pocetna'
          title='pocetna' className='my-auto min-w-8'>
      <svg width="155" height="50" viewBox="0 0 155 50" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <path d="M68.3649 35.7932C60.9362 35.7932 57.2357 30.9474 57.2357 23.5043C57.2357 15.8134 61.15 11.1787 68.7926 11.1787C71.7493 11.1787 74.1294 11.8854 75.9425 13.3538C77.6439 14.8681 78.6109 16.9331 78.8619 19.5304H75.8681C74.7989 19.5304 74.0179 19.044 73.553 18.0895C72.7348 16.3733 71.1356 15.4922 68.7833 15.4922C64.1903 15.4922 62.1727 18.6861 62.1727 23.4952C62.1727 28.1666 64.1252 31.4706 68.6439 31.4706C71.74 31.4706 73.5158 29.791 74.0458 27.1479H78.8526C78.3877 32.6637 74.4827 35.7932 68.3649 35.7932Z" fill="#323667"/>          <path d="M85.9004 26.2389C85.9376 29.8549 87.1835 31.6813 89.6008 31.6813C92.0182 31.6813 93.2269 29.7907 93.2641 26.1012C93.2641 22.4852 92.0554 20.6956 89.638 20.6956C87.137 20.6956 85.9004 22.5587 85.9004 26.2389ZM98.2011 26.2389C98.2011 32.6266 95.3188 35.8571 89.6287 35.8571C83.9386 35.8571 81.0935 32.6266 81.0935 26.2389C81.0935 19.7778 83.9386 16.584 89.6287 16.584C95.3188 16.5932 98.2011 19.787 98.2011 26.2389Z" fill="#323667"/>
        <path d="M105.137 26.1015C105.099 29.8552 106.382 31.755 109.088 31.755C111.794 31.755 113.142 29.8644 113.142 26.1015C113.04 22.5222 111.719 20.7693 109.162 20.7693C106.596 20.7693 105.276 22.5222 105.137 26.1015ZM117.828 13.9227V27.4047C117.828 32.9848 114.871 35.7564 109.004 35.7197C103.175 35.6188 100.255 32.4892 100.255 26.3126C100.255 19.9892 102.747 16.7586 107.758 16.5934C110.101 16.5934 111.924 17.5387 113.123 19.4752V11.5732H115.466C116.768 11.5732 117.828 12.6562 117.828 13.9227Z" fill="#323667"/>
        <path d="M124.773 24.1372H131.849C131.57 21.6776 130.389 20.4203 128.362 20.4203C126.335 20.4111 125.127 21.6776 124.773 24.1372ZM128.362 35.8571C122.672 35.6827 119.827 32.4155 119.827 26.1012C119.827 19.787 122.709 16.6207 128.437 16.584C134.415 16.584 137.223 20.2 136.86 27.4687H124.699C124.876 30.3138 126.122 31.7547 128.437 31.7547C129.543 31.7547 130.566 31.2958 131.496 30.4147C131.923 29.9559 132.453 29.7448 133.095 29.7448H136.581C135.54 33.682 132.983 35.8571 128.362 35.8571Z" fill="#323667"/>
        <path d="M155 15.2163L147.887 25.9634L154.145 35.4439H148.454L145.117 29.5427L141.742 35.4439H135.94L142.058 25.9267L136.293 16.8683H141.732L144.968 22.485L148.594 16.4461C149.022 15.6385 149.7 15.2163 150.658 15.2163H155Z" fill="#323667"/>
        <path d="M121.91 14.2071H124.987V13.4545H122.765V12.3991H123.974C124.28 12.3991 124.429 12.2614 124.42 11.9769V11.7108H122.765V10.8297H124.987V10.123H122.347C122.049 10.1322 121.91 10.2791 121.91 10.5636V14.2071Z" fill="#3AADE2"/>
        <path d="M128.056 13.5738C127.498 13.5738 127.21 13.3535 127.182 12.9222H126.336C126.363 13.8124 126.931 14.2621 128.056 14.2621C129.134 14.2621 129.692 13.8675 129.748 13.069C129.776 12.5551 129.543 12.2155 129.069 12.0595C128.716 11.9402 128.372 11.8484 128.056 11.775C127.498 11.6557 127.228 11.4721 127.228 11.2151C127.247 10.9031 127.47 10.7379 127.925 10.7379C128.297 10.7379 128.548 10.848 128.697 11.0866C128.781 11.2427 128.902 11.3253 129.088 11.3253H129.58C129.534 10.4809 129.013 10.0587 128.009 10.0587C126.959 10.0496 126.429 10.4717 126.41 11.3344C126.41 11.8759 126.819 12.243 127.656 12.4541C127.963 12.5275 128.251 12.601 128.502 12.6744C128.762 12.7478 128.892 12.8763 128.892 13.0782C128.874 13.4086 128.604 13.5738 128.056 13.5738Z" fill="#3AADE2"/>
        <path d="M131.012 10.5636V10.8481H132.267V14.1979H133.141V10.8481H134.396V10.123H131.449C131.207 10.123 131.012 10.3341 131.012 10.5636Z" fill="#3AADE2"/>
        <path d="M139.807 11.573H140.281C140.272 11.004 140.505 10.7195 140.96 10.7195C141.397 10.7378 141.611 10.9398 141.583 11.3527C141.565 11.5547 141.49 11.7199 141.351 11.83C141.09 12.0411 140.784 12.2522 140.44 12.4632C140.095 12.6743 139.835 12.9221 139.64 13.2158C139.454 13.5279 139.361 13.8674 139.342 14.2254H142.42V13.4911H140.56C140.588 13.3627 140.691 13.2433 140.895 13.1149C141.611 12.6468 142.057 12.298 142.215 12.087C142.355 11.9126 142.438 11.674 142.457 11.3619C142.411 10.49 141.918 10.0679 140.951 10.0679C140.105 10.0679 139.603 10.4166 139.463 11.1141C139.426 11.417 139.528 11.573 139.807 11.573Z" fill="#3AADE2"/>
        <path d="M145.265 10.7286C145.721 10.7286 145.962 11.2058 145.962 12.1695C145.972 13.1239 145.739 13.6012 145.265 13.6012C144.791 13.6012 144.568 13.1239 144.586 12.1603C144.577 11.1966 144.81 10.7286 145.265 10.7286ZM145.265 10.0586C144.224 10.0586 143.694 10.7561 143.685 12.1511C143.657 13.5553 144.187 14.262 145.265 14.262C146.344 14.262 146.874 13.5645 146.855 12.1603C146.836 10.7653 146.306 10.0586 145.265 10.0586Z" fill="#3AADE2"/>
        <path d="M148.073 10.8661V11.4626H148.873V14.2159H149.747V10.554C149.747 10.2695 149.598 10.1318 149.301 10.1318H149.04C148.919 10.5357 148.547 10.8661 148.073 10.8661Z" fill="#3AADE2"/>
        <path d="M152.936 12.6465H151.755L152.936 10.8569V12.6465ZM152.927 14.2067H153.773V13.3073H154.284V12.6282H153.773V10.554C153.773 10.2695 153.633 10.1318 153.345 10.1318H152.703L151.086 12.5731V13.3073H152.936V14.2067H152.927Z" fill="#3AADE2"/>
        <path d="M34.9031 28.7445V21.2647H37.4879C43.2245 21.2647 46.7761 15.8682 45.4559 11.0775C45.1212 9.86601 44.5354 8.64538 43.5313 7.62665C38.9197 2.9185 32.4672 0 25.3266 0C11.3431 0 0 11.1968 0 25C0 38.8032 11.3431 50 25.3266 50C32.4672 50 38.9197 47.0815 43.522 42.3825C44.647 41.2353 45.2606 39.8678 45.5489 38.5004C46.5623 33.6913 43.0478 28.7353 37.4879 28.7353H34.9031V28.7445Z" fill="url(#paint0_linear_201_2572)"/>
        <path opacity="0.5" d="M20.5385 37.7846H15.6852C14.2161 37.7846 13.0168 36.6098 13.0168 35.1506V18.6491C13.0168 17.1991 14.2068 16.0151 15.6852 16.0151H20.5385C22.0075 16.0151 23.2069 17.1899 23.2069 18.6491V35.1506C23.2069 36.6007 22.0168 37.7846 20.5385 37.7846Z" fill="url(#paint1_linear_201_2572)"/>
        <path opacity="0.5" d="M20.5385 37.7846H15.6852C14.2161 37.7846 13.0168 36.6098 13.0168 35.1506V18.6491C13.0168 17.1991 14.2068 16.0151 15.6852 16.0151H20.5385C22.0075 16.0151 23.2069 17.1899 23.2069 18.6491V35.1506C23.2069 36.6007 22.0168 37.7846 20.5385 37.7846Z" fill="url(#paint2_linear_201_2572)"/>
        <path d="M32.7463 37.7847H25.8754C24.4063 37.7847 23.207 36.61 23.207 35.1507V14.3449C23.207 12.8949 24.397 11.7109 25.8754 11.7109H32.7463C34.2153 11.7109 35.4147 12.8857 35.4147 14.3449V35.1507C35.4054 36.6008 34.2153 37.7847 32.7463 37.7847Z" fill="white"/>
        <path opacity="0.5" d="M10.5717 37.7847H7.4291C6.08095 37.7847 4.98384 36.7017 4.98384 35.371V23.027C4.98384 21.6962 6.08095 20.6133 7.4291 20.6133H10.5717C11.9198 20.6133 13.017 21.6962 13.017 23.027V35.3618C13.017 36.7017 11.9198 37.7847 10.5717 37.7847Z" fill="url(#paint3_linear_201_2572)"/>
        <defs>
        <linearGradient id="paint0_linear_201_2572" x1="-0.227791" y1="-1.80543" x2="42.8885" y2="44.6142" gradientUnits="userSpaceOnUse">
        <stop stop-color="#24E8B9"/>
        <stop offset="1" stop-color="#29A9F5"/>
        </linearGradient>
        <linearGradient id="paint1_linear_201_2572" x1="23.0672" y1="26.8997" x2="23.2462" y2="26.8997" gradientUnits="userSpaceOnUse">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="white"/>
        </linearGradient>
        <linearGradient id="paint2_linear_201_2572" x1="13.0194" y1="26.8997" x2="23.2064" y2="26.8997" gradientUnits="userSpaceOnUse">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="white"/>
        </linearGradient>
        <linearGradient id="paint3_linear_201_2572" x1="4.98133" y1="29.1952" x2="13.0196" y2="29.1952" gradientUnits="userSpaceOnUse">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="white"/>
        </linearGradient>
        </defs>
      </svg>
      </AnchorLink>

      <div className="space-x-4 md:flex align-middle hidden text-dark-blue font-body text-lg font-medium">
          <AnchorLink 
          to='/#pocetna'
          title='pocetna'
            className="my-auto "
            
            
          >
            Početna
          </AnchorLink>
          <AnchorLink
            to='/#usluge'
            title='usluge'
            className="stripped my-auto "
            
          >
            Usluge
          </AnchorLink>

          <AnchorLink
            to='/#cjenovnik'
            title='cjenovnik'
            className="stripped my-auto "
          >
            Cjenovnik
          </AnchorLink>
          <AnchorLink
            to='/#o-nama'
            title='o-nama'
            className="stripped my-auto "
          >
            O Nama
          </AnchorLink>
          <AnchorLink
            to='/#kontakt'
            title='kontakt'
            className="stripped my-auto "
          >
            Kontakt
          </AnchorLink>
        </div>

        <a href="tel:+38765569196" className='hidden lg:flex my-auto  text-dark-blue font-body text-lg font-medium '>
          <span className='mr-2'>
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23Z" fill="url(#paint0_linear_241_2375)"/>
              <path d="M16.7099 14.9943V5.69691C16.7099 4.65916 15.8628 3.81201 14.825 3.81201H8.17491C7.13715 3.81201 6.29001 4.65916 6.29001 5.69691V14.9943H16.7099ZM11.5 4.99802C11.8176 4.99802 12.0718 5.25216 12.0718 5.56984C12.0718 5.88752 11.8176 6.14166 11.5 6.14166C11.1823 6.14166 10.9281 5.88752 10.9281 5.56984C10.9281 5.25216 11.1823 4.99802 11.5 4.99802Z" fill="white"/>
              <path d="M6.29001 16.1167V17.3027C6.29001 18.3405 7.13715 19.1876 8.17491 19.1876H14.825C15.8628 19.1876 16.7099 18.3405 16.7099 17.3027V16.1167H6.29001ZM11.5 18.1287C11.1823 18.1287 10.9281 17.8745 10.9281 17.5568C10.9281 17.2392 11.1823 16.985 11.5 16.985C11.8176 16.985 12.0718 17.2392 12.0718 17.5568C12.0718 17.8745 11.8176 18.1287 11.5 18.1287Z" fill="white"/>
              <defs>
              <linearGradient id="paint0_linear_241_2375" x1="-10.4675" y1="-30.1696" x2="46.4956" y2="77.8815" gradientUnits="userSpaceOnUse">
              <stop stop-color="#24E8B9"/>
              <stop offset="1" stop-color="#29A9F5"/>
              </linearGradient>
              </defs>
            </svg>
          </span>+38765/569-196</a>

          <nav className='hidden md:flex items-middle font-display text-lg text-gray'>
            <Link to='/' className='my-auto' activeClassName='text-dark-blue'>SR</Link>
            <span className='my-auto px-1 text-dark-blue'>|</span>
            <Link to='/en' className='my-auto' activeClassName='text-dark-blue'>EN</Link>
          </nav>
      
          <div className="block md:hidden py-1 my-auto">
             <button className='flex items-center px-3 py-2 text-dark-blue' onClick={toggleSidebar}>
             <svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M36 24H0V20H36V24ZM36 14H0V10H36V14ZM36 4H0V0H36V4Z" fill="#1E3A8A"/>
<title>Menu</title>
</svg>

                 
                 
             </button>
      </div>
    </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
